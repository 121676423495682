<template>
  <div class="row bgq2">
    <div class="d-flex flex-column text-white align-items-center justify-content-center">

      <h1 style="font-size: 3em; font-weight: 600;"> สรุปผลคะแนน </h1>
      <i :class="icon" style="font-size: 8em;"></i>
      <h1 class="text-center">" {{ data_status }} "</h1>

      <h4 class="text-center">คะแนนของคุณ คือ {{ data_answer.average_score }}</h4>

      <i :class="icon2" style="font-size: 8em;"></i>
      <h1 class="text-center">" {{ data_status2 }} "</h1>
      <h4 class="text-center">คะแนนเฉลี่ยของคนในเมือง คือ {{ data_answer.average_people }}</h4>

      <div class="col-6 text-center mt-5">
        <button class="bt-main" @click="back">
          กลับสู่หน้าหลัก
        </button>
      </div>
    </div>





  </div>
</template>

<script>
import { defineComponent, onMounted, inject, ref } from "vue";
import * as Yup from "yup";
//import useSweetalert from "@/service/sweetalert2";
export default defineComponent({
  name: "q2_end",
  setup() {
    const store = inject("store");
    const router = inject("router");
    const data_answer = store.getters.getAnswer;
    const data_status = ref('');
    const icon = ref('');

    const data_status2 = ref('');
    const icon2 = ref('');

    const checkscore = (score) => {

      if (score >= 1.0 && score <= 1.80) {
        icon.value = 'bi bi-emoji-frown-fill';
        data_status.value = 'เมืองของคุณไม่มีความน่าอยู่';
      } else if (score >= 1.81 && score <= 2.60) {
        icon.value = 'bi bi-emoji-expressionless-fill';
        data_status.value = 'เมืองของคุณน่าอยู่น้อย';
      } else if (score >= 2.61 && score <= 3.40) {
        icon.value = 'bi bi-emoji-neutral-fill';
        data_status.value = 'เมืองของคุณน่าอยู่ปานกลาง';
      } else if (score >= 3.41 && score <= 4.20) {
        icon.value = 'bi bi-emoji-smile-fill';
        data_status.value = 'เมืองของคุณน่าอยู่มาก';
      } else if (score >= 4.21 && score <= 5.00) {
        icon.value = 'bi bi-emoji-heart-eyes-fill';
        data_status.value = 'เมืองของคุณน่าอยู่มากที่สุด';
      }

    }

    const checkscore_city = (score) => {

      if (score >= 1.0 && score <= 1.80) {
        icon2.value = 'bi bi-emoji-frown-fill';
        data_status2.value = 'เมืองของคุณไม่มีความน่าอยู่';
      } else if (score >= 1.81 && score <= 2.60) {
        icon2.value = 'bi bi-emoji-expressionless-fill';
        data_status2.value = 'เมืองของคุณน่าอยู่น้อย';
      } else if (score >= 2.61 && score <= 3.40) {
        icon2.value = 'bi bi-emoji-neutral-fill';
        data_status2.value = 'เมืองของคุณน่าอยู่ปานกลาง';
      } else if (score >= 3.41 && score <= 4.20) {
        icon2.value = 'bi bi-emoji-smile-fill';
        data_status2.value = 'เมืองของคุณน่าอยู่มาก';
      } else if (score >= 4.21 && score <= 5.00) {
        icon2.value = 'bi bi-emoji-heart-eyes-fill';
        data_status2.value = 'เมืองของคุณน่าอยู่มากที่สุด';
      }

    }
    // const data_status = checkscore(data_answer.average_score);



    const back = () => {
      router.push({ name: "Home" });
    }



    const register = Yup.object().shape({
      ans_1_1: Yup.string(),
      ans_1_2: Yup.string(),
      ans_1_3: Yup.string()
    });

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }

      checkscore(parseInt(data_answer.average_score));
      checkscore_city(parseInt(data_answer.average_people))
      //checkscore(parseInt(3));
    });





    return {
      register,
      data_answer,
      checkscore,
      data_status,
      icon,
      data_status2,
      icon2,
      back
    };
  },
});
</script>

<style scoped>
.bdhd {
  border-bottom: 2px solid #dee2e6 !important;
}

.icon {
  width: 50px;
  padding: 5px;
}

.gender {
  padding: 30px 20px;
  border: 1px solid #dee2e6;
  border-radius: 10px;
}

input[type="radio"] {
  display: none;
}

input:checked+span {
  color: var(--color);
  border: 2px solid var(--color);
}

.bgq2 {
  height: 100vh;
  background-position: center;
  background-size: cover;
  background-image: url("~@/assets/servey/Q2/bg.png");
}

.score {
  display: flex;
  background-color: antiquewhite;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}

.bt-main {
  width: 100%;
  min-height: 60px;
  background-color: var(--color);
  color: white;
  border-radius: 10px;
  font-size: 20px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease-out;
}

.bt-main:hover {
  background-color: #622b99;
}

.bt-main:active {
  background-color: #A548FF;
  transition: background-color 0s;
  opacity: 0.5;
}
</style>
