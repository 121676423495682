export const SET_REGISTER = "setRegister";
export const SET_ANSWER = "setAnswer";

export default {
  state: {
    register: [],
    answer: [],
  },
  getters: {
    getRegister(state) {
      return state.register;
    },
    getAnswer(state) {
      return state.answer;
    },
  },
  actions: {
    [SET_REGISTER](state, payload) {
      state.commit(SET_REGISTER, payload);
    },
    [SET_ANSWER](state, payload) {
      state.commit(SET_ANSWER, payload);
    },
  },
  mutations: {
    [SET_REGISTER](state, payload) {
      state.register = {
        ...state.register,
        ...payload,
      };
    },
    [SET_ANSWER](state, payload) {
      state.answer = {
        ...state.answer,
        ...payload,
      };
    },
  },
};
