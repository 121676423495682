<template>
  <div class="vh100">

    <div class="container p-0 position-relative">

      <div class="text-center" style="padding-top:10%;color:var(--serveycolor); ">
        <h5 class="title-text" style="font-size: 55px;">เมนู</h5>
        <h5 class="title-text">สร้างเมืองน่าอยู่</h5>
        <h5 class="sub-title-text">เลือกรายการเข้าร่วมสร้างเมือง</h5>

        
      </div>

      <div class="d-flex justify-content-center" style="padding-bottom: 5%;">
        <!-- {{ Profile }}  -->
        <div class="line"></div>
      </div>
    </div>


    <div class="container position-relative" style="height: 55%;">
      <div class="row">
        <div class="col-12 botton-box" @click="gopage('Q1START', 1)" style="background-color: #00C4CC;">
          เมืองของคุณน่าอยู่แค่ไหน ? <br>
        </div>
      </div>
      <div class="row">
        <div class="col-12 botton-box" @click="gopage('Q2START', 2)" style="background-color: #A548FF;">
          คุณมีความสุขแค่ไหนในการอาศัยในเมือง ?
        </div>
      </div>
      <div class="row">
        <div class="col-12 botton-box" @click="gopage('Q3START', 3)" style="background-color:#FFA53B;">
          ชีวิตด้านไหน ชิ้นใหญ่กว่ากัน ?
        </div>
      </div>




      <!-- <div class="row">
        <div class="col-6 botton-box" style="background-color: #A548FF;">
          ของดีอยู่ตรงใหน
        </div>
        <div class="col-6 botton-box" style="background-color: #FFA53B;">
          เดินทางกันเถอะ
        </div>
      </div>
      <div class="row">
        <div class="col-6 botton-box" style="background-color:#6422B8;">
          อยู่ดีสุขภาพดี
        </div>
        <div class="col-6 botton-box" style="background-color:#FFD93B;color: var(--serveycolor);">
          ความสุขของคุณ
        </div>
      </div> -->


    </div>



    <div class="text-center" style="height: 10%;">



    </div>

    <div class="container position-absolute bottom-0" style="height: 60px">
      <div class="d-flex justify-content-center align-item-center">

        <!-- <button type="button" @click="back" class="nextbutton">
          หน้าต่อไป >
        </button> -->

      </div>
    </div>









  </div>
</template>

<script>
import { SET_ANSWER } from "@/store/modules/RegisterModel";
import { defineComponent, onMounted, inject } from "vue";
import useSweetalert from "@/service/sweetalert2";
export default defineComponent({
  name: "Main",
  components: {

  },
  setup() {
    const store = inject("store");
    const router = inject("router");
    const { SconfirmNc } = useSweetalert();

    const Profile = store.getters.getProfire;

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    const gopage = (page_name, question) => {

      switch (question) {
        case 1:
          console.log(Profile.data.q1)
          if (Profile.data.q1 != null) {
            // SconfirmNc("ขอบคุณที่ ตอบคำถามของเรา !!", "success", "ตกลง").then(
            // );

            let data = []
            data.average_score = Profile.data.q1.average_score;
            data.average_people = Profile.average_q1; 
            store.dispatch(SET_ANSWER, data);


            router.push({ name: "Q1_END" });
          } else {
            router.push({ name: page_name });
          }
          break;
        case 2:
          if (Profile.data.q2 != null) {
            // SconfirmNc("ขอบคุณที่ ตอบคำถามของเรา !!", "success", "ตกลง").then(
            // );
            let data = []
            data.average_score = Profile.data.q2.average_score;
            data.average_people = Profile.average_q2; 
            store.dispatch(SET_ANSWER, data);

            router.push({ name: "Q2_END" });
          } else {
            router.push({ name: page_name });
          }
          break;
        case 3:
          if (Profile.data.q3 != null) {
            SconfirmNc("ขอบคุณที่ ตอบคำถามของเรา !!", "success", "ตกลง").then(
            );
          } else {
            // SconfirmNc("พบกันเร็วๆนี้ !!", "info", "ตกลง").then(
            // );
            router.push({ name: page_name });
          }
          break;

      }

    }


    return {
      gopage,
      Profile
    };
  },
});
</script>

<style scoped>
.title-text {
  font-family: "Poppins", sans-serif !important;
  font-size: 38px;
  font-weight: 600;
}

.sub-title-text {
  font-family: "Poppins", sans-serif !important;
  font-size: 18px;
  font-weight: 600;
}

.line {
  width: 80%;
  border-top: 2px solid var(--serveycolor);
  /* Replace with your desired color */
  margin: 20px 0;
}

.botton-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  width: 100%;
  font-size: 25px;
  font-weight: 600;
  color: white;
  font-family: "Poppins", sans-serif !important;
  text-align: center;
}

.flex-item {
  width: 50%;
  height: 50px;
}
</style>
