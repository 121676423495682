<template>
  <div class="row bgq2">
    <div class="col-12 f13">
      <div class="card concard card-shadow">
        <div class="card-header text-center text-white" style="background-color: var(--serveycolor);">
          <!-- <img src="@/assets/logo.png" style="height: 100px" alt="..." /> -->
          <div class="t1" style="margin: 5px;font-size: 3rem; font-weight: 600;">คุณมีความสุขแค่ไหนในการอาศัยในเมือง ?</div>


        </div>
        <Form @submit="onSubmitRegister" :validation-schema="register" id="kt_account_profile_details_form"
          ref="formContainer" novalidate="novalidate">
          <div class="card-body mb-47 mt-2 white">
            <!--begin::Card body-->
            <div class="card-body">

              <label style="font-size: 1.8rem;font-weight: 600; ">สุขภาพ (Health)</label>
              <label style="font-size: 1.2rem;font-weight: 300; ">มากที่สุด= 5 คะแนน / มาก = 4 คะแนน / ปานกลาง = 3 คะแนน /
                น้อย = 2 คะแนน / ไม่อย่างยิ่ง = 1 คะแนน</label>
              <div class="row mb-6">

                <!--begin::Label-->

                <label class="col-lg-4 col-form-label required fw-bold fs-6">สามารถเข้ารับการรักษาพยาบาล (ภาครัฐ) {{
                  data_register.ans_4_1 = '' ? '0' : data_register.ans_4_1 }}</label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">

                  <Field type="range" name="ans_4_1" class="form-range" v-model="data_register.ans_4_1" step="1" min="1"
                    max="5" />
                </div>

                <!--end::Col-->
              </div>

              <div class="row mb-6">

                <!--begin::Label-->

                <label class="col-lg-4 col-form-label required fw-bold fs-6">สามารถเข้ารับการรักษาพยาบาล (ภาคเอกชน) {{
                  data_register.ans_4_2 = '' ? '0' : data_register.ans_4_2 }}</label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">

                  <Field type="range" name="ans_4_2" class="form-range" v-model="data_register.ans_4_2" step="1" min="1"
                    max="5" />
                </div>

                <!--end::Col-->
              </div>

              <div class="row mb-6">

                <!--begin::Label-->

                <label
                  class="col-lg-4 col-form-label required fw-bold fs-6">ความมั่นใจ อุ่นใจในสวัสดิการด้านสุขภาพ
                  {{ data_register.ans_4_3 = '' ? '0' : data_register.ans_4_3 }}</label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">

                  <Field type="range" name="ans_4_3" class="form-range" v-model="data_register.ans_4_3" step="1" min="1"
                    max="5" />
                </div>

                <!--end::Col-->
              </div>

            


            </div>
            <!--end::Card body-->
          </div>
          <div class="card-footer white">
            <div class="d-flex justify-center align-center">
              <div style="width: 100%; padding: 0px 5px">
                <button type="button" @click="back" class="button btn-back">
                  ย้อนกลับ
                </button>
              </div>
              <div style="width: 100%; padding: 0px 5px">
                <button type="submit"
                  style="width: 100%; font-size: 1.5rem !important; background-color: var(--serveycolor); "
                  class="button">
                  ถัดไป
                </button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>

import { defineComponent, onMounted, inject } from "vue";
import { SET_ANSWER } from "@/store/modules/RegisterModel";
import { SET_SCORE } from "@/store/modules/Score";
import { Field, Form } from "vee-validate";
import * as Yup from "yup";
//import useSweetalert from "@/service/sweetalert2";
export default defineComponent({
  name: "Register",
  components: {

    Field,
    Form,
  },
  setup() {
    const store = inject("store");
    const router = inject("router");
    const data_register = store.getters.getAnswer;

    data_register.ans_4_1 = 1;
    data_register.ans_4_2 = 1;
    data_register.ans_4_3 = 1;


    const register = Yup.object().shape({
      ans_4_1: Yup.string(),
      ans_4_2: Yup.string(),
      ans_4_3: Yup.string(),
  
    });

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    const onSubmitRegister = (values) => {
      // checkCard(values.id_card);
      // if (check_personal_id.value) {

      console.log(values);
      store.dispatch(SET_SCORE, values);
      store.dispatch(SET_ANSWER, values);
      router.push({ name: "Q2_5" });
      // }
    };


    const back = () => {
      router.push({ name: "Q2_3" });
    };


    return {
      onSubmitRegister,
      register,
      data_register,
      back,
    };
  },
});
</script>

<style scoped>
.bdhd {
  border-bottom: 2px solid #dee2e6 !important;
}

.icon {
  width: 50px;
  padding: 5px;
}

.gender {
  padding: 30px 20px;
  border: 1px solid #dee2e6;
  border-radius: 10px;
}

input[type="radio"] {
  display: none;
}

input:checked+span {
  color: var(--color);
  border: 2px solid var(--color);
}

.bgq2 {
  height: 100vh;
  background-position: center;
  background-size: cover;
  background-image: url("~@/assets/servey/Q2/bg.png");
}

.score {
  display: flex;
  background-color: antiquewhite;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}</style>
