<template>
  <div class="row">
    <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" :color="color" loader="dots" />
    <div class="col-12 f13">
      <div class="card concard card-shadow">
        <div class="card-header white text-center">
          <img src="@/assets/logo.png" style="height: 100px" alt="..." />
          <div class="t1" style="margin: 5px">ข้อมูลเพิ่มเติม (2)</div>
        </div>
        <Form @submit="onSubmitRegister" :validation-schema="register" id="kt_account_profile_details_form" class="form"
          novalidate="novalidate">
          <div class="card-body mb-47 mt-2 white">
            <!--begin::Card body-->
            <div class="card-body">

              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6">ลักษณะแหล่งที่มาของรายได้จากการทำงาน </label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field as="select" name="job" class="form-control  form-control-solid" @change="check_job($event)"
                    v-model="data_register.job">
                    <option value="" selected disabled>โปรดเลือก</option>
                    <option value="ไม่ได้ทำงานหารายได้">ไม่ได้ทำงานหารายได้</option>
                    <option value="ลูกจ้างรัฐบาล / รัฐวิสาหกิจ">ลูกจ้างรัฐบาล / รัฐวิสาหกิจ</option>
                    <option value="ลูกจ้างเอกชน">ลูกจ้างเอกชน</option>
                    <option value="ประกอบธุรกิจของตนเอง">ประกอบธุรกิจของตนเอง</option>
                    <option value="อื่นๆ">อื่นๆ (Other)</option>
                  </Field>
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="job" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row mt-1" v-if="job_status">
                  <Field type="text" name="job_other" class="form-control  form-control-solid" placeholder="ลักษณะแหล่งที่มาของรายได้จากการทำงาน"
                    v-model="data_register.job_other" />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="job_other" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>

              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6">รายได้จากการทำงาน (บาทต่อเดือน)</label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field type="number" name="salary" class="form-control form-control-solid" placeholder="รายได้จากการทำงาน (บาทต่อเดือน)"
                    v-model="data_register.salary" />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="salary" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>

              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6">สถานภาพทางสังคม </label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field as="select" name="social" class="form-control  form-control-solid" @change="check_social($event)"
                    v-model="data_register.social">
                    <option value="" selected disabled>โปรดเลือก</option>
                    <option value="ไม่มีตำแหน่งทางสังคม">ไม่มีตำแหน่งทางสังคม </option>
                    <option value="มีตำแหน่งทางราชการ">มีตำแหน่งทางราชการ</option>
                    <option value="มีตำแหน่งทางเอกชน">มีตำแหน่งทางเอกชน</option>
                    <option value="มีตำแหน่งภาคประชาชน">มีตำแหน่งภาคประชาชน</option>
                    <option value="อื่นๆ">อื่นๆ (Other)</option>
                  </Field>
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="social" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row mt-1" v-if="social_status">
                  <Field type="text" name="social_other" class="form-control  form-control-solid" placeholder="สถานภาพทางสังคม"
                    v-model="data_register.social_other" />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="social_other" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>

              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6">การมีทะเบียนบ้าน </label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field as="select" name="home_status" class="form-control  form-control-solid"
                    v-model="data_register.home_status">
                    <option value="" selected disabled>โปรดเลือก</option>
                    <option value="ไม่มีทะเบียนบ้าน">ไม่มีทะเบียนบ้าน</option>
                    <option value="มีทะเบียนบ้านของตนเอง">มีทะเบียนบ้านของตนเอง</option>
                    <option value="อาศัยทะเบียนบ้านผู้อื่น">อาศัยทะเบียนบ้านผู้อื่น</option>
                  </Field>
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="home_status" />
                    </div>
                  </div>
                </div>
        
              </div>



            </div>

          </div>
          <div class="card-footer white">
            <div class="d-flex justify-center align-center">
              <div style="width: 100%; padding: 0px 5px">
                <button type="button" @click="back" class="button btn-back">
                  ย้อนกลับ
                </button>
              </div>
              <div style="width: 100%; padding: 0px 5px">
                <button type="submit" style="width: 100%" class="button">
                  ลงทะเบียน
                </button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { defineComponent, ref, onMounted, inject } from "vue";
import { SET_REGISTER } from "@/store/modules/RegisterModel";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";

import useRegister from "@/service/api/register";
import useSweetalert from "@/service/sweetalert2";
import liff from "@line/liff";
export default defineComponent({
  name: "Register",
  components: {
    ErrorMessage,
    Field,
    Form,
    Loading,
  },
  setup() {
    const store = inject("store");
    const router = inject("router");
    const { Sconfirm, SconfirmNc, SToast } = useSweetalert();
    const { Register } = useRegister();
    let isLoading = ref(false);
    let color = ref(process.env.VUE_APP_COLOR);
    const data_register = store.getters.getRegister;

    let edu_status = ref(false);
    let sta_status = ref(false);
    let na_status = ref(false);
    let re_status = ref(false);
    let job_status = ref(false);
    let social_status = ref(false);


    const register = Yup.object().shape({
      job: Yup.string().required("กรุณาเลือก แหล่งที่มาของรายได้").label("แหล่งที่มาของรายได้"),
      job_other: Yup.string()
        .when("job", {
          is: (job) => job === "อื่นๆ",
          then: Yup.string().required("กรุณากรอก แหล่งที่มาของรายได้"),
        })
        .label("แหล่งที่มาของรายได้"),
      salary: Yup.string().required("กรุณาเลือก กรอกรายได้จากการทำงาน (ต่อเดือน)").label("รายได้จากการทำงาน"),
      social: Yup.string().required("กรุณาเลือก สถานภาพทางสังคม").label("สถานภาพทางสังคม"),
      social_other: Yup.string()
        .when("social", {
          is: (social) => social === "อื่นๆ",
          then: Yup.string().required("กรุณากรอก สถานภาพทางสังคม"),
        })
        .label("สถานภาพทางสังคม"),
        home_status: Yup.string().required("กรุณาเลือก สถานะการมีทะเบียบ้าน").label("สถานะการมีทะเบียบ้าน"),
    });

    function checkedu_lv(event) {
      if (event.target.value === "อื่นๆ") {
        edu_status.value = true;
      } else {
        edu_status.value = false;
      }
    }

    function check_sta(event) {
      if (event.target.value === "อื่นๆ") {
        sta_status.value = true;
      } else {
        sta_status.value = false;
      }
    }

    function check_na(event) {
      if (event.target.value === "อื่นๆ") {
        na_status.value = true;
      } else {
        na_status.value = false;
      }
    }


    function check_re(event) {
      if (event.target.value === "อื่นๆ") {
        re_status.value = true;
      } else {
        re_status.value = false;
      }
    }

    function check_job(event) {
      if (event.target.value === "อื่นๆ") {
        job_status.value = true;
      } else {
        job_status.value = false;
      }
    }


    function check_social(event) {
      if (event.target.value === "อื่นๆ") {
        social_status.value = true;
      } else {
        social_status.value = false;
      }
    }



    const back = () => {
      router.push({ name: "Register_3" });
    };

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    const onSubmitRegister = (values) => {

      store.dispatch(SET_REGISTER, values);
      console.log(store.getters.getRegister);
      Sconfirm("ยืนยัน การลงทะเบียน", "question").then(async (result) => {
        if (result.isConfirmed) {
          isLoading.value = true;

          await Register(store.getters.getRegister)
            .then((result) => {
              isLoading.value = false;
              if (result.data.status === "success") {
                SconfirmNc("ลงทะเบียนสำเร็จ", "success", "ตกลง").then(() => {
                  if (store.getters.getUrlCallBack.id) {
                    liff.ready.then(() => {
                      liff.openWindow({
                        url: store.getters.getUrlCallBack.id,
                        external: false,
                      });
                    });
                  } else {
                    router.push({ name: "Home" });
                  }
                });
              } else {
                SconfirmNc("ไม่มีสิทธิ์เข้าใช้งาน", "error", "ตกลง").then(
                  () => {
                    liff.ready.then(() => {
                      liff.closeWindow();
                    });
                  }
                );
              }
            })
            .catch(() => {
              SToast("error", "เกิดข้อผิดผาด กรุณาลองใหม่อีกครั้ง");
            });
        }
      });
    };




    return {
      back,
      onSubmitRegister,
      register,
      data_register,
      isLoading,
      color,
      edu_status,
      sta_status,
      na_status,
      re_status,
      job_status,
      social_status,
      checkedu_lv,
      check_sta,
      check_na,
      check_re,
      check_job,
      check_social
    };
  },
});
</script>

<style scoped>
.icon {
  width: 50px;
  padding: 5px;
}

.gender {
  padding: 30px 20px;
  border: 1px solid #dee2e6;
}

input[type="radio"] {
  display: none;
}

input:checked+span {
  color: var(--color);
  border: 2px solid var(--color);
}
</style>
