<template>
  <div class="row">
    <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" :color="color" loader="dots" />
    <div class="col-12 f13">
      <div class="card concard card-shadow">
        <div class="card-header white text-center">
          <img src="@/assets/logo.png" style="height: 100px" alt="..." />
          <div class="t1" style="margin: 5px">ข้อมูลเพิ่มเติม (1)</div>
        </div>
        <Form @submit="onSubmitRegister" :validation-schema="register" id="kt_account_profile_details_form" class="form"
          novalidate="novalidate">
          <div class="card-body mb-47 mt-2 white">
            <!--begin::Card body-->
            <div class="card-body">

              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6">ระดับการศึกษา </label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field as="select" name="edu_lv" class="form-control  form-control-solid" @change="checkedu_lv($event)"
                    v-model="data_register.edu_lv">
                    <option value="" selected disabled>โปรดเลือก</option>
                    <option value="มัธยมต้น">มัธยมต้น</option>
                    <option value="มัธยมปลาย">มัธยมปลาย</option>
                    <option value="ปวช">ปวช</option>
                    <option value="ปวส">ปวส</option>
                    <option value="ปริญาตรี">ปริญาตรี</option>
                    <option value="ปริญาโท">ปริญาโท</option>
                    <option value="ปริญญาเอก">ปริญญาเอก</option>
                    <option value="อื่นๆ">อื่นๆ (Other)</option>
                  </Field>
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="edu_lv" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row mt-1" v-if="edu_status">
                  <Field type="text" name="edu_lv_other" class="form-control  form-control-solid"
                    placeholder="ระดับการศึกษา" v-model="data_register.edu_lv_other" />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="edu_lv_other" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>

              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6">สถานภาพ </label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field as="select" name="status" class="form-control  form-control-solid" @change="check_sta($event)"
                    v-model="data_register.status">
                    <option value="" selected disabled>โปรดเลือก</option>
                    <option value="โสด">โสด</option>
                    <option value="สมรส">สมรส</option>
                    <option value="อื่นๆ">อื่นๆ (Other)</option>
                  </Field>
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="status" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row mt-1" v-if="sta_status">
                  <Field type="text" name="status_other" class="form-control  form-control-solid" placeholder="สถานภาพ"
                    v-model="data_register.status_other" />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="status_other" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>

              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6">สัญชาติ (ประเทศที่เกิด)</label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field as="select" name="nationality" class="form-control  form-control-solid"
                    @change="check_na($event)" v-model="data_register.nationality">
                    <option value="" selected disabled>โปรดเลือก</option>
                    <option value="สัญชาติไทย">สัญชาติไทย</option>
                    <option value="อื่นๆ">อื่นๆ (Other)</option>
                  </Field>
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="nationality" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row mt-1" v-if="na_status">
                  <Field type="text" name="nationality_other" class="form-control  form-control-solid"
                    placeholder="สัญชาติ" v-model="data_register.nationality_other" />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="nationality_other" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>

              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6">ศาสนา </label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field as="select" name="religion" class="form-control  form-control-solid" @change="check_re($event)"
                    v-model="data_register.religion">
                    <option value="" selected disabled>โปรดเลือก</option>
                    <option value="พุทธ">พุทธ</option>
                    <option value="คริสต์">คริสต์</option>
                    <option value="อิสลาม">อิสลาม</option>
                    <option value="อื่นๆ">อื่นๆ (Other)</option>
                  </Field>
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="religion" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row mt-1" v-if="re_status">
                  <Field type="text" name="religion_other" class="form-control  form-control-solid" placeholder="สัญชาติ"
                    v-model="data_register.religion_other" />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="religion_other" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>


            </div>

          </div>
          <div class="card-footer white">
            <div class="d-flex justify-center align-center">
              <div style="width: 100%; padding: 0px 5px">
                <button type="button" @click="back" class="button btn-back">
                  ย้อนกลับ
                </button>
              </div>
              <div style="width: 100%; padding: 0px 5px">
                <button type="submit" style="width: 100%" class="button">
                  ถัดไป
                </button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { defineComponent, ref, onMounted, inject } from "vue";
import { SET_REGISTER } from "@/store/modules/RegisterModel";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
export default defineComponent({
  name: "Register",
  components: {
    ErrorMessage,
    Field,
    Form,
    Loading,
  },
  setup() {
    const store = inject("store");
    const router = inject("router");
    let isLoading = ref(false);
    let color = ref(process.env.VUE_APP_COLOR);
    const data_register = store.getters.getRegister;

    let edu_status = ref(false);
    let sta_status = ref(false);
    let na_status = ref(false);
    let re_status = ref(false);


    const register = Yup.object().shape({
      edu_lv: Yup.string().required("กรุณาเลือก ระดับการศึกษา").label("ระดับการศึกษา"),
      edu_lv_other: Yup.string()
        .when("edu_lv", {
          is: (edu_lv) => edu_lv === "อื่นๆ",
          then: Yup.string().required("กรุณากรอก ระดับการศึกษา"),
        })
        .label("ระดับการศึกษา"),

      status: Yup.string().required("กรุณาเลือก สถานภาพ").label("สถานภาพ"),
      status_other: Yup.string()
        .when("status", {
          is: (status) => status === "อื่นๆ",
          then: Yup.string().required("กรุณากรอก สถานภาพ"),
        })
        .label("สถานภาพ"),
      religion: Yup.string().required("กรุณาเลือก ศาสนา").label("ศาสนา"),
      religion_other: Yup.string()
        .when("religion", {
          is: (religion) => religion === "อื่นๆ",
          then: Yup.string().required("กรุณากรอก ศาสนา"),
        })
        .label("สถานภาพ"),

      nationality: Yup.string().required("กรุณาเลือก สัญชาติ").label("สัญชาติ"),
      nationality_other: Yup.string()
        .when("nationality", {
          is: (nationality) => nationality === "อื่นๆ",
          then: Yup.string().required("กรุณากรอก สัญชาติ"),
        })
        .label("สัญชาติ"),
    });

    function checkedu_lv(event) {
      if (event.target.value === "อื่นๆ") {
        edu_status.value = true;
      } else {
        edu_status.value = false;
      }
    }

    function check_sta(event) {
      if (event.target.value === "อื่นๆ") {
        sta_status.value = true;
      } else {
        sta_status.value = false;
      }
    }

    function check_na(event) {
      if (event.target.value === "อื่นๆ") {
        na_status.value = true;
      } else {
        na_status.value = false;
      }
    }


    function check_re(event) {
      if (event.target.value === "อื่นๆ") {
        re_status.value = true;
      } else {
        re_status.value = false;
      }
    }




    const back = () => {
      router.push({ name: "Register_2" });
    };

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });


    const onSubmitRegister = (values) => {
      store.dispatch(SET_REGISTER, values);
      router.push({ name: "Register_4" });
    };



    return {
      back,
      onSubmitRegister,
      register,
      data_register,
      isLoading,
      color,
      edu_status,
      sta_status,
      na_status,
      re_status,
      checkedu_lv,
      check_sta,
      check_na,
      check_re,
    };
  },
});
</script>

<style scoped>
.icon {
  width: 50px;
  padding: 5px;
}

.gender {
  padding: 30px 20px;
  border: 1px solid #dee2e6;
}

input[type="radio"] {
  display: none;
}

input:checked+span {
  color: var(--color);
  border: 2px solid var(--color);
}
</style>
