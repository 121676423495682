import ApiService from "@/service/ApiService";

export default function useRegister() {
  const Register = async (data) => {
    return await ApiService.post("register_servey", data);
  };

  const Question1 = async (data) => {
    return await ApiService.post("question1", data);
  };

  const Question2 = async (data) => {
    return await ApiService.post("question2", data);
  };

  
  const Question3 = async (data) => {
    return await ApiService.post("question3", data);
  };




  // const GetCommunitys = async () => {
  //   return await ApiService.get("scx/get_communitys");
  // };

  // const UpdateUser = async (data) => {
  //   return await ApiService.put("scx/edit_user/" + data.id_line, data);
  // };

  // const History = async () => {
  //   return await ApiService.get("scx/register_student_history");
  // };

  return {
    Register,
    Question1,
    Question2,
    Question3,
    // UpdateUser,
    // GetCommunitys,
    History
  };
}
