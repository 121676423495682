export const SET_SCORE = "setScore";

export default {
  state: {
    score: [],
  },
  getters: {
    getScore(state) {
      return state.score;
    },
    getSumScore(state) {
      return Object.values(state.score).reduce((acc, curr) => {
        return acc + Number(curr);
      }, 0);
    },
  },
  actions: {
    [SET_SCORE]({ commit }, payload) {
      commit(SET_SCORE, payload);
    },
  },
  mutations: {
    [SET_SCORE](state, payload) {
      state.score = {
        ...state.score,
        ...payload,
      };
    },
  },
};