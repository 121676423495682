<template>
  <div class="vh100">

    <div class="container p-0"
      style="border-radius: 0px 0px 50px 50px; background-color:var(--serveycolor); padding:0px; height: 40%;">

      <div class="title-text text-center text-white" style="padding:25px">
        ยินดีต้อนรับ <br>
        สู่การตามหา <br>
        เมืองน่าอยู่


      </div>

    </div>

    <img class="row position-absolute" src="@/assets/servey/home.png" style="width: 80%; left: 50%;
        top: 45%;
        transform: translate(-50%, -50%);" />


    <div class="container position-absolute bottom-0 text-center" style="height: 30%;">

      <button class="startbutton" @click="start" style="width: 75%;">เริ่มกันเลย !!!</button> <br>  <br> <br>
  
      <a href="">Don't Have an Account? Sigh Up</a>
    </div>












  </div>
</template>

<script>
import { defineComponent, onMounted, inject } from "vue";


export default defineComponent({
  name: "Profile",
  components: {

  },
  setup() {
    const store = inject("store");
    const router = inject("router");

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    const start = () => {
      router.push({ name: "Main" });
    }


    return {
      start
    };
  },
});
</script>

<style scoped>
.title-text {
  font-family: "Poppins", sans-serif !important;
  font-size: 38px;
  font-weight: 600;
}
</style>
