<template>
  <div class="vh100">

    <div class="container p-0 position-relative"
      style="border-radius: 0px 0px 70px 70px; background-color:var(--serveycolor); padding:0px; height: 30%;">


    </div>

    <img class="row position-absolute" src="@/assets/servey/Q2/set.png" style="width: 80%; left: 50%;
        top: 30%;
        transform: translate(-50%, -50%);" />






    <div class="text-center position-relative" style="margin-top: 13em; color: var(--serveycolor);">

      <div class="title-text">การเดินทาง <br>(Transport)</div>
      <div class="sub-title-text ps-5 pe-5 pt-2 text-start lh-sm">
        &nbsp;&nbsp;&nbsp;หมายถึง การได้รับการบริการของเมืองโดยพื้นฐานทางด้านการเดินทาง เช่น การเข้าถึงระบบขนส่งสาธารณะ คุณภาพถนนในพื้นที่ คุณภาพพื้นที่ทางเท้าในพื้นที่ เป็นต้น
      </div>


    </div>

    <div class="container position-absolute bottom-0" style="height: 60px">
      <div class="d-flex justify-content-center align-item-center">

        <button type="button" @click="gopage('Q2P4')" class="nextbutton">
          ต่อไป >
        </button>

      </div>
    </div>









  </div>
</template>

<script>
import { defineComponent, onMounted, inject } from "vue";


export default defineComponent({
  name: "Set2",
  components: {

  },
  setup() {
    const store = inject("store");
    const router = inject("router");

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    const gopage = (page_name) => {
      router.push({ name: page_name });
    }


    return {
      gopage
    };

  },
});
</script>

<style scoped>
.title-text {
  font-family: "Prompt", sans-serif !important;
  font-size: 38px;
  font-weight: 600;
}

.sub-title-text {
  font-family: "Prompt", sans-serif !important;
  font-size: 18px;
  font-weight: 400;
}
</style>
