<template>
  <div class="vh100 bgq2">

    <div class="container p-0 position-relative text-white text-center">

      <div class="image-container">
        <img class="position-relative" src="@/assets/servey/Q2/set.png" style="width: 30%;" />
        <span class="title-text">อาชีพ
          และการทำงาน </span>
      </div>






      <div class="d-flex justify-content-center" style="padding-bottom: 5%;">
        <div class="line"></div>
      </div>
    </div>





    <div class="text-center text-white" style="height: 10%;">

      <span class="title-text">Page1</span>

    </div>

    <div class="container position-absolute bottom-0" style="height: 60px">
      <div class="d-flex justify-content-center align-item-center">

        <button type="button" @click="gopage('Q2set3')" class="nextbutton">
          ต่อไป >
        </button>

      </div>
    </div>









  </div>
</template>

<script>
import { defineComponent, onMounted, inject } from "vue";


export default defineComponent({
  name: "QP1",
  components: {

  },
  setup() {
    const store = inject("store");
    const router = inject("router");

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    const gopage = (page_name) => {
      router.push({ name: page_name });
    }


    return {
      gopage
    };

  },

});
</script>

<style scoped>
.image-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.title-text {
  font-size: 35px;
  font-weight: 600;
  margin-top: auto;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
}

.sub-title-text {

  font-size: 18px;
  font-weight: 600;
}

.line {
  width: 80%;
  border-top: 2px solid white;
  /* Replace with your desired color */
  margin: 20px 0;
}


.bgq2 {
  height: 100vh;
  background-position: center;
  background-size: cover;
  background-image: url("~@/assets/servey/Q2/bg.png");
}
</style>
