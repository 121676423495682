<template>
  <div class="row">
    <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" :color="color" loader="dots" />
    <div class="col-12 f13">
      <div class="card concard card-shadow">
        <div class="card-header text-center text-white" style="background-color: var(--serveycolor);">
          <!-- <img src="@/assets/logo.png" style="height: 100px" alt="..." /> -->
          <div class="t1" style="margin: 5px;font-size: 3rem; font-weight: 600;"> ชีวิตด้านไหน ชิ้นใหญ่กว่ากัน ?</div>


        </div>
        <Form @submit="onSubmitRegister" :validation-schema="register" id="kt_account_profile_details_form"
          ref="formContainer" novalidate="novalidate">
          <div class="card-body mb-47 mt-2 white">
            <!--begin::Card body-->
            <div class="card-body">

              <label style="font-size: 1.8rem;font-weight: 800; ">การบริหารเวลากับความสุข </label>
              <!-- <label style="font-size: 1.2rem;font-weight: 300; ">มากที่สุด= 5 คะแนน / มาก = 4 คะแนน / ปานกลาง = 3 คะแนน /
                น้อย = 2 คะแนน / ไม่อย่างยิ่ง = 1 คะแนน</label> -->
              <div class="row mb-6">

                <label class="col-lg-4 col-form-label required fw-bold fs-6" style="font-size: 20px !important;">ใน 1 วัน
                  24 ชั่วโมง ท่านใช้เวลากับด้านใดไปเท่าไร </label>

                <div class="col-lg-4 fv-row h3">

                  <Field type="checkbox" name="ans_1_1" class="form-check-input" value="1" v-model="data_register.ans_1_1"
                    @change=check_change_h(1) />
                  &nbsp;<span>ด้านที่อยู่อาศัย {{ data_register.ans_1_1 != null ? data_register.ans_1_1h + '&nbsp;'
                    + "ชั่วโมง " : "" }} </span>

                  <Field v-if="data_register.ans_1_1" type="range" name="ans_1_1h" class="form-range"
                    v-model="data_register.ans_1_1h" step="1" min="0" max="24" />
                  <br>
                  <Field type="checkbox" name="ans_1_2" class="form-check-input" value="1" v-model="data_register.ans_1_2"
                    @change=check_change_h(2) />
                  &nbsp;<span>ด้านอาชีพ และการทำงาน {{ data_register.ans_1_2 != null ? data_register.ans_1_2h + '&nbsp;'
                    + "ชั่วโมง " : "" }} </span>
                  <Field v-if="data_register.ans_1_2" type="range" name="ans_1_2h" class="form-range"
                    v-model="data_register.ans_1_2h" step="1" min="0" max="24" />
                  <br>

                  <Field type="checkbox" name="ans_1_3" class="form-check-input" value="1" v-model="data_register.ans_1_3"
                    @change=check_change_h(3) />
                  &nbsp;<span>ด้านการเดินทาง {{ data_register.ans_1_3 != null ? data_register.ans_1_3h + '&nbsp;'
                    + "ชั่วโมง " : "" }} </span>
                  <Field v-if="data_register.ans_1_3" type="range" name="ans_1_3h" class="form-range"
                    v-model="data_register.ans_1_3h" step="1" min="0" max="24" />
                  <br>

                  <Field type="checkbox" name="ans_1_4" class="form-check-input" value="1" v-model="data_register.ans_1_4"
                    @change=check_change_h(4) />
                  &nbsp;<span>ด้านการพักผ่อนสันทนาการ {{ data_register.ans_1_4 != null ? data_register.ans_1_4h + '&nbsp;'
                    + "ชั่วโมง " : "" }} </span>
                  <Field v-if="data_register.ans_1_4" type="range" name="ans_1_4h" class="form-range"
                    v-model="data_register.ans_1_4h" step="1" min="0" max="24" />
                  <br>

                </div>




                <h2 class="text-center" v-if='sum_h <= 24' style="color: green;"> {{ 'รวม ' + sum_h + ' ชั่วโมง' }}</h2>
                <h2 class="text-center" v-else style="color: rgb(245, 6, 6);"> {{ 'รวม ' + sum_h + ' ชั่วโมง' }}</h2>


              </div>

              <div class="row mb-6">

                <label class="col-lg-4 col-form-label required fw-bold fs-6" style="font-size: 20px !important;">รายได้ใน
                  1 เดือน ท่านใช้จ่ายกับด้านใดคิดเป็นเท่าไร (เต็ม 100 เปอร์เซ็นต์)</label>

                <div class="col-lg-4 fv-row h3">

                  <Field type="checkbox" name="ans_2_1" class="form-check-input" value="1" v-model="data_register.ans_2_1"
                    @change=check_change_p(1) />
                  &nbsp;<span>ด้านที่อยู่อาศัย {{ data_register.ans_2_1 != null ? data_register.ans_2_1p + '&nbsp;'
                    + "%" : "" }} </span>

                  <Field v-if="data_register.ans_2_1" type="range" name="ans_2_1p" class="form-range"
                    v-model="data_register.ans_2_1p" step="1" min="0" max="100" />
                  <br>
                  <Field type="checkbox" name="ans_2_2" class="form-check-input" value="1" v-model="data_register.ans_2_2"
                    @change=check_change_p(2) />
                  &nbsp;<span>ด้านทุนของอาชีพ และการทำงาน {{ data_register.ans_2_2 != null ? data_register.ans_2_2p +
                    '&nbsp;'
                    + "%" : "" }} </span>
                  <Field v-if="data_register.ans_2_2" type="range" name="ans_2_2p" class="form-range"
                    v-model="data_register.ans_2_2p" step="1" min="0" max="100" />
                  <br>

                  <Field type="checkbox" name="ans_2_3" class="form-check-input" value="1" v-model="data_register.ans_2_3"
                    @change=check_change_p(3) />
                  &nbsp;<span>ด้านการเดินทาง {{ data_register.ans_2_3 != null ? data_register.ans_2_3p + '&nbsp;'
                    + "%" : "" }} </span>
                  <Field v-if="data_register.ans_2_3" type="range" name="ans_2_3p" class="form-range"
                    v-model="data_register.ans_2_3p" step="1" min="0" max="100" />
                  <br>

                  <Field type="checkbox" name="ans_2_4" class="form-check-input" value="1" v-model="data_register.ans_2_4"
                    @change=check_change_p(4) />
                  &nbsp;<span>ด้านการพักผ่อนสันทนาการ {{ data_register.ans_2_4 != null ? data_register.ans_2_4p + '&nbsp;'
                    + "%" : "" }} </span>
                  <Field v-if="data_register.ans_2_4" type="range" name="ans_2_4p" class="form-range"
                    v-model="data_register.ans_2_4p" step="1" min="0" max="100" />
                  <br>

                  <Field type="checkbox" name="ans_2_5" class="form-check-input" value="1" v-model="data_register.ans_2_5"
                    @change=check_change_p(5) />
                  &nbsp;<span>เงินเก็บออม {{ data_register.ans_2_5 != null ? data_register.ans_2_5p + '&nbsp;'
                    + "%" : "" }} </span>
                  <Field v-if="data_register.ans_2_5" type="range" name="ans_2_5p" class="form-range"
                    v-model="data_register.ans_2_5p" step="1" min="0" max="100" />
                  <br>

                </div>


                <h2 class="text-center" v-if='sum_p <= 100' style="color: green;"> {{ sum_p + ' %' }} </h2>
                <h2 class="text-center" v-else style="color: rgb(245, 6, 6);"> {{ sum_p + ' %' }} </h2>


              </div>

              <div class="row mb-6">

                <!--begin::Label-->

                <label class="col-lg-4 col-form-label required fw-bold fs-6"
                  style="font-size: 20px !important;">โดยรวมแล้วท่านพอใจความสุขกับการดำรงชีวิตในเมืองหรือไม่
                  {{ data_register.ans_3_1 = '' ? '0' : data_register.ans_3_1 }}</label>
                <label style="font-size: 1.2rem;font-weight: 300; ">( 1 = ไม่พอใจ 2 = พอใจน้อย 3 = พอใจปานกลาง 4 = พอใจมาก
                  5 = พอใจมากที่สุด)</label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">

                  <Field type="range" name="ans_3_3" class="form-range" v-model="data_register.ans_3_1" step="1" min="1"
                    max="5" />
                </div>

                <!--end::Col-->
              </div>



            </div>
            <!--end::Card body-->
          </div>
          <div class="card-footer white">
            <div class="d-flex justify-center align-center">
              <div style="width: 100%; padding: 0px 5px">
                <button type="button" @click="back" class="button btn-back">
                  ย้อนกลับ
                </button>
              </div>
              <div style="width: 100%; padding: 0px 5px">
                <button type="submit"
                  style="width: 100%; font-size: 1.5rem !important; background-color: var(--serveycolor); "
                  class="button">
                  ส่งคำตอบ
                </button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, inject, computed } from "vue";
import { SET_ANSWER } from "@/store/modules/RegisterModel";
import { Field, Form } from "vee-validate";
import * as Yup from "yup";
import Loading from "vue-loading-overlay";
import useRegister from "@/service/api/register";
import useSweetalert from "@/service/sweetalert2";
import liff from "@line/liff";
export default defineComponent({
  name: "Register",
  components: {
    Loading,
    Field,
    Form,
  },
  setup() {
    const store = inject("store");
    const router = inject("router");
    const data_register = store.getters.getAnswer;
    const { Sconfirm, SconfirmNc, SToast } = useSweetalert();
    const { Question3 } = useRegister();



    const check_change_h = (number) => {


      if (!data_register.ans_1_1 && number == 1) {
        data_register.ans_1_1h = 0;
      } else if (!data_register.ans_1_2 && number == 2) {
        data_register.ans_1_2h = 0;
      } else if (!data_register.ans_1_3 && number == 3) {
        data_register.ans_1_3h = 0;
      } else if (!data_register.ans_1_4 && number == 4) {
        data_register.ans_1_4h = 0;
      } else if (!data_register.ans_1_5 && number == 5) {
        data_register.ans_1_5h = 0;
      }

    }

    const check_change_p = (number) => {
      if (!data_register.ans_2_1 && number == 1) {
        data_register.ans_2_1p = 0;
      } else if (!data_register.ans_2_2 && number == 2) {
        data_register.ans_2_2p = 0;
      } else if (!data_register.ans_2_3 && number == 3) {
        data_register.ans_2_3p = 0;
      } else if (!data_register.ans_2_4 && number == 4) {
        data_register.ans_2_4p = 0;
      } else if (!data_register.ans_2_5 && number == 5) {
        data_register.ans_2_5p = 0;
      }
    }

    const sum_h = computed(() => {
      // let sum = 0;

      // if (sum > 24) {
      //   sum = 24;
      //   SconfirmNc("ผลรามต้องไม่เกิน 24 ชม.", "error", "ตกลง")


      //   return sum;
      // }
      return parseInt(data_register.ans_1_1h) + parseInt(data_register.ans_1_2h) + parseInt(data_register.ans_1_3h) + parseInt(data_register.ans_1_4h);
    });

    const sum_p = computed(() => {
      return parseInt(data_register.ans_2_1p) + parseInt(data_register.ans_2_2p) + parseInt(data_register.ans_2_3p) + parseInt(data_register.ans_2_4p) + parseInt(data_register.ans_2_5p);
    });
    // data_register.ans_1_1 = 'on';
    // data_register.ans_1_2 = 'on';
    // data_register.ans_1_3 = 'on';
    // data_register.ans_1_4 = 'on';



    data_register.ans_1_1h = 0;
    data_register.ans_1_2h = 0;
    data_register.ans_1_3h = 0;
    data_register.ans_1_4h = 0;

    data_register.ans_2_1p = 0;
    data_register.ans_2_2p = 0;
    data_register.ans_2_3p = 0;
    data_register.ans_2_4p = 0;
    data_register.ans_2_5p = 0;


    data_register.ans_3_1 = 1;

    let isLoading = ref(false);
    let color = ref(process.env.VUE_APP_COLOR);

    const register = Yup.object().shape({
      ans_1_1: Yup.string(),
      ans_1_1h: Yup.string(),
      ans_1_2: Yup.string(),
      ans_1_2h: Yup.string(),
      ans_1_3: Yup.string(),
      ans_1_3h: Yup.string(),
      ans_1_4: Yup.string(),
      ans_1_4h: Yup.string(),
      ans_2_1: Yup.string(),
      ans_2_1p: Yup.string(),
      ans_2_2: Yup.string(),
      ans_2_2p: Yup.string(),
      ans_2_3: Yup.string(),
      ans_2_3p: Yup.string(),
      ans_2_4: Yup.string(),
      ans_2_4p: Yup.string(),
      ans_2_5: Yup.string(),
      ans_2_5p: Yup.string(),
      ans_3_1: Yup.string(),
    });

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    const onSubmitRegister = (values) => {
      // checkCard(values.id_card);
      // if (check_personal_id.value) {

      if (sum_h.value > 24) {
        SToast("error", "เวลาคุณเกิน 24 ชั่วโมง");
      } else if (sum_p.value > 100) {
        SToast("error", "เปอร์เซนต์คุณเกิน 100%");
      } else if (sum_h.value > 24 && sum_p.value > 100) {
        SToast("error", "กรุณาตรวสอบข้อมูลให้ถูกต้อง");
      } else {

        values.ans_1_1h = 0;
        values.ans_1_2h = 0;
        values.ans_1_3h = 0;
        values.ans_1_4h = 0;

        values.ans_2_1p = 0;
        values.ans_2_2p = 0;
        values.ans_2_3p = 0;
        values.ans_2_4p = 0;
        values.ans_2_5p = 0;

        values.id_line = store.getters.getAccessToken.accesstoken;
        store.dispatch(SET_ANSWER, values);

        console.log(store.getters.getAnswer);
        Sconfirm("ยืนยัน การส่งคำตอบ", "question").then(async (result) => {
          if (result.isConfirmed) {
            isLoading.value = true;

            await Question3(store.getters.getAnswer)
              .then((result) => {
                isLoading.value = false;
                if (result.data.status === "success") {
                  SconfirmNc("ขอบคุณสำหรับความคิดเห็น", "success", "ตกลง").then(() => {
                    if (store.getters.getUrlCallBack.id) {
                      liff.ready.then(() => {
                        liff.openWindow({
                          url: store.getters.getUrlCallBack.id,
                          external: false,
                        });
                      });
                    } else {
                      //router.push({ name: "Home" });
                      liff.ready.then(() => {
                        liff.closeWindow();
                      });
                    }
                  });
                } else {
                  SconfirmNc("ไม่มีสิทธิ์เข้าใช้งาน", "error", "ตกลง").then(
                    () => {
                      liff.ready.then(() => {
                        liff.closeWindow();
                      });
                    }
                  );
                }
              })
              .catch(() => {
                SToast("error", "เกิดข้อผิดผาด กรุณาลองใหม่อีกครั้ง");
              });
          }
        });

      }


    };

    const back = () => {
      router.push({ name: "Q1_3" });
    };



    return {
      onSubmitRegister,
      register,
      data_register,
      back,
      color,
      isLoading,
      sum_h,
      sum_p,
      check_change_h,
      check_change_p


    };
  },
});
</script>

<style scoped>
.bdhd {
  border-bottom: 2px solid #dee2e6 !important;
}

.icon {
  width: 50px;
  padding: 5px;
}

.gender {
  padding: 30px 20px;
  border: 1px solid #dee2e6;
  border-radius: 10px;
}

input[type="radio"] {
  display: none;
}

/* input:checked+span {
  color: var(--color);
  border: 2px solid var(--color);
} */

.bgq2 {
  height: 100vh;
  background-position: center;
  background-size: cover;
  background-image: url("~@/assets/servey/Q2/bg.png");
}

.score {
  display: flex;
  background-color: antiquewhite;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}
</style>
