<template>
  <div class="row">
    <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" :color="color" loader="dots" />
    <div class="col-12 f13">
      <div class="card concard card-shadow">
        <div class="card-header text-center text-white" style="background-color: var(--serveycolor);">
          <!-- <img src="@/assets/logo.png" style="height: 100px" alt="..." /> -->
          <div class="t1" style="margin: 5px;font-size: 3rem; font-weight: 600;">เมืองของคุณน่าอยู่แค่ไหน ?</div>


        </div>
        <Form @submit="onSubmitRegister" :validation-schema="register" id="kt_account_profile_details_form"
          ref="formContainer" novalidate="novalidate">
          <div class="card-body mb-47 mt-2 white">
            <!--begin::Card body-->
            <div class="card-body">

              <label style="font-size: 1.8rem;font-weight: 600; ">ด้านการพักผ่อนสันทนาการ (Recreation and Public
                Services)</label>
              <label style="font-size: 1.2rem;font-weight: 300; ">มากที่สุด= 5 คะแนน / มาก = 4 คะแนน / ปานกลาง = 3 คะแนน /
                น้อย = 2 คะแนน / ไม่อย่างยิ่ง = 1 คะแนน</label>
              <div class="row mb-6">

                <!--begin::Label-->

                <label class="col-lg-4 col-form-label required fw-bold fs-6">สามารถเข้าถึงพื้นที่สาธารณะ {{
                  data_register.ans_4_1 = '' ? '0' : data_register.ans_4_1 }}</label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">

                  <Field type="range" name="ans_4_1" class="form-range" v-model="data_register.ans_4_1" step="1" min="1"
                    max="5" />
                </div>

                <!--end::Col-->
              </div>

              <div class="row mb-6">

                <!--begin::Label-->

                <label
                  class="col-lg-4 col-form-label required fw-bold fs-6">สามารถเข้าถึงพื้นที่สิ่งอำนวยความสะดวกด้านกีฬา {{
                    data_register.ans_4_2 = '' ? '0' : data_register.ans_4_2 }}</label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">

                  <Field type="range" name="ans_4_2" class="form-range" v-model="data_register.ans_4_2" step="1" min="1"
                    max="5" />
                </div>

                <!--end::Col-->
              </div>

              <div class="row mb-6">

                <!--begin::Label-->

                <label
                  class="col-lg-4 col-form-label required fw-bold fs-6">สามารถเข้าถึงพื้นที่วัฒนธรรมหรือแหล่งเรียนรู้ภูมิปัญญาท้องถิ่น
                  {{ data_register.ans_4_3 = '' ? '0' : data_register.ans_4_3 }}</label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">

                  <Field type="range" name="ans_4_3" class="form-range" v-model="data_register.ans_4_3" step="1" min="1"
                    max="5" />
                </div>

                <!--end::Col-->
              </div>

              <div class="row mb-6">

                <!--begin::Label-->

                <label class="col-lg-4 col-form-label required fw-bold fs-6">สามารถเข้าถึงพื้นที่เพื่อการท่องเที่ยว
                  {{ data_register.ans_4_4 = '' ? '0' : data_register.ans_4_4 }}</label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">

                  <Field type="range" name="ans_4_4" class="form-range" v-model="data_register.ans_4_4" step="1" min="1"
                    max="5" />
                </div>

                <!--end::Col-->
              </div>

              <div class="row mb-6">

                <!--begin::Label-->

                <label class="col-lg-4 col-form-label required fw-bold fs-6">สามารถเข้าถึงการใช้งานอินเทอร์เน็ต {{
                  data_register.ans_4_5 = '' ? '0' : data_register.ans_4_5 }}</label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">

                  <Field type="range" name="ans_4_5" class="form-range" v-model="data_register.ans_4_5" step="1" min="1"
                    max="5" />
                </div>

                <!--end::Col-->
              </div>


            </div>
            <!--end::Card body-->
          </div>
          <div class="card-footer white">
            <div class="d-flex justify-center align-center">
              <div style="width: 100%; padding: 0px 5px">
                <button type="button" @click="back" class="button btn-back">
                  ย้อนกลับ
                </button>
              </div>
              <div style="width: 100%; padding: 0px 5px">
                <button type="submit"
                  style="width: 100%; font-size: 1.5rem !important; background-color: var(--serveycolor); "
                  class="button">
                  ส่งคำตอบ
                </button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, inject } from "vue";
import { SET_ANSWER } from "@/store/modules/RegisterModel";
import { SET_SCORE } from "@/store/modules/Score";
import { Field, Form } from "vee-validate";
import * as Yup from "yup";
import Loading from "vue-loading-overlay";
import useRegister from "@/service/api/register";
import useSweetalert from "@/service/sweetalert2";
import liff from "@line/liff";
export default defineComponent({
  name: "Register",
  components: {
    Loading,
    Field,
    Form,
  },
  setup() {
    const store = inject("store");
    const router = inject("router");
    const data_register = store.getters.getAnswer;
    const { Sconfirm, SconfirmNc, SToast } = useSweetalert();
    const { Question1 } = useRegister();

    let isLoading = ref(false);
    let color = ref(process.env.VUE_APP_COLOR);

    
    data_register.ans_4_1 = 1;
    data_register.ans_4_2 = 1;
    data_register.ans_4_3 = 1;
    data_register.ans_4_4 = 1;
    data_register.ans_4_5 = 1;

    const register = Yup.object().shape({
      ans_4_1: Yup.string(),
      ans_4_2: Yup.string(),
      ans_4_3: Yup.string(),
      ans_4_4: Yup.string(),
      ans_4_5: Yup.string(),
    });

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    const onSubmitRegister = (values) => {
    
  
      store.dispatch(SET_SCORE, values);
      values.sum_score = store.getters.getSumScore;
      values.average_score = store.getters.getSumScore / 20;
      values.id_line = store.getters.getAccessToken.accesstoken;
      store.dispatch(SET_ANSWER, values);

      console.log(store.getters.getAnswer);
      Sconfirm("ยืนยัน การส่งคำตอบ", "question").then(async (result) => {
        if (result.isConfirmed) {
          isLoading.value = true;

          await Question1(store.getters.getAnswer)
            .then((result) => {
              isLoading.value = false;
              if (result.data.status === "success") {
                SconfirmNc("ขอบคุณสำหรับความคิดเห็น", "success", "ตกลง").then(() => {
                  if (store.getters.getUrlCallBack.id) {
                    liff.ready.then(() => {
                      liff.openWindow({
                        url: store.getters.getUrlCallBack.id,
                        external: false,
                      });
                    });
                  } else {
                    //router.push({ name: "Home" });
                    values.average_people = result.data.average_people; // คะแนนเฉลี่ยของคนทั้งเมือง
                    //    values.personal_percent = result.data.personal_percent; // คุรเป็รกี่เปอร์เซ็นของเมือง

                    store.dispatch(SET_ANSWER, values);
                    router.push({ name: "Q1_END" });
                  }
                });
              } else {
                SconfirmNc("ไม่มีสิทธิ์เข้าใช้งาน", "error", "ตกลง").then(
                  () => {
                    liff.ready.then(() => {
                      liff.closeWindow();
                    });
                  }
                );
              }
            })
            .catch(() => {
              SToast("error", "เกิดข้อผิดผาด กรุณาลองใหม่อีกครั้ง");
            });
        }
      });
    };

    const back = () => {
      router.push({ name: "Q1_3" });
    };



    return {
      onSubmitRegister,
      register,
      data_register,
      back,
      color,
      isLoading
    };
  },
});
</script>

<style scoped>
.bdhd {
  border-bottom: 2px solid #dee2e6 !important;
}

.icon {
  width: 50px;
  padding: 5px;
}

.gender {
  padding: 30px 20px;
  border: 1px solid #dee2e6;
  border-radius: 10px;
}

input[type="radio"] {
  display: none;
}

input:checked+span {
  color: var(--color);
  border: 2px solid var(--color);
}

.bgq2 {
  height: 100vh;
  background-position: center;
  background-size: cover;
  background-image: url("~@/assets/servey/Q2/bg.png");
}

.score {
  display: flex;
  background-color: antiquewhite;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}
</style>
