import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";
import Register from "@/views/register/Register.vue";
import Register_1 from "@/views/register/Register_1.vue";
import Register_2 from "@/views/register/Register_2.vue";
import Register_3 from "@/views/register/Register_3.vue";
import Register_4 from "@/views/register/Register_4.vue";
import Profile from "@/views/profile/Profile.vue";
import Edit from "@/views/edit/Edit.vue";
import Edit_1 from "@/views/edit/Edit_1.vue";
import Dashboard from "@/views/Dashboard.vue"; // new
import Main from "@/views/servey/Main.vue"; // new

//คำถามที่ 2
import Q2start from "@/views/servey/Q2/Start.vue";
import Q2set from "@/views/servey/Q2/Set.vue";
import Q2set2 from "@/views/servey/Q2/Set2.vue";
import Q2set3 from "@/views/servey/Q2/Set3.vue";
import Q2set4 from "@/views/servey/Q2/Set4.vue";
import Q2P1 from "@/views/servey/Q2/P1.vue";
import Q2P2 from "@/views/servey/Q2/P2.vue";
import Q2P3 from "@/views/servey/Q2/P3.vue";
import Q2P4 from "@/views/servey/Q2/P4.vue";
import Q2P5 from "@/views/servey/Q2/P5.vue";

import Q1START from "@/views/servey/Q1/q1_start.vue";
import Q1_2 from "@/views/servey/Q1/q1_2.vue";
import Q1_3 from "@/views/servey/Q1/q1_3.vue";
import Q1_4 from "@/views/servey/Q1/q1_4.vue";
import Q1END from "@/views/servey/Q1/q1_end.vue";

import Q2START from "@/views/servey/Q2/q2_start.vue";
import Q2_2 from "@/views/servey/Q2/q2_2.vue";
import Q2_3 from "@/views/servey/Q2/q2_3.vue";
import Q2_4 from "@/views/servey/Q2/q2_4.vue";
import Q2_5 from "@/views/servey/Q2/q2_5.vue";
import Q2END from "@/views/servey/Q2/q2_end.vue";

import Q3START from "@/views/servey/Q3/q3_start.vue";

import Summary from "@/views/servey/Summary.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/register/step1",
    name: "Register_1",
    component: Register_1,
  },
  {
    path: "/register/step2",
    name: "Register_2",
    component: Register_2,
  },
  {
    path: "/register/step3",
    name: "Register_3",
    component: Register_3,
  },
  {
    path: "/register/step4",
    name: "Register_4",
    component: Register_4,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/edit",
    name: "Edit",
    component: Edit,
  },
  {
    path: "/edit/step1",
    name: "Edit_1",
    component: Edit_1,
  },
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
  },

  {
    path: "/",
    name: "Main",
    component: Main,
  },

  {
    path: "/",
    name: "Q2start",
    component: Q2start,
  },

  {
    path: "/",
    name: "Q2set",
    component: Q2set,
  },

  {
    path: "/",
    name: "Q2set2",
    component: Q2set2,
  },

  {
    path: "/",
    name: "Q2set3",
    component: Q2set3,
  },

  {
    path: "/",
    name: "Q2set4",
    component: Q2set4,
  },

  {
    path: "/",
    name: "Q2P1",
    component: Q2P1,
  },

  {
    path: "/",
    name: "Q2P2",
    component: Q2P2,
  },

  {
    path: "/",
    name: "Q2P3",
    component: Q2P3,
  },

  {
    path: "/",
    name: "Q2P4",
    component: Q2P4,
  },
  {
    path: "/",
    name: "Q2P5",
    component: Q2P5,
  },

  {
    path: "/",
    name: "Summary",
    component: Summary,
  },

  {
    path: "/",
    name: "Q1START",
    component: Q1START,
  },

  {
    path: "/",
    name: "Q1_END",
    component: Q1END,
  },

  {
    path: "/",
    name: "Q1_2",
    component: Q1_2,
  },

  {
    path: "/",
    name: "Q1_3",
    component: Q1_3,
  },

  {
    path: "/",
    name: "Q1_4",
    component: Q1_4,
  },

  {
    path: "/",
    name: "Q2START",
    component: Q2START,
  },

  {
    path: "/",
    name: "Q2_2",
    component: Q2_2,
  },

  {
    path: "/",
    name: "Q2_3",
    component: Q2_3,
  },

  {
    path: "/",
    name: "Q2_4",
    component: Q2_4,
  },

  {
    path: "/",
    name: "Q2_5",
    component: Q2_5,
  },
  {
    path: "/",
    name: "Q2_END",
    component: Q2END,
  },

  {
    path: "/",
    name: "Q3START",
    component: Q3START,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
